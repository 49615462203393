import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/messaging";
import { onMessage } from 'firebase/messaging';
import { useSelector } from "react-redux";
import Loader from "../../components/loader/Loader";

const FirebaseData = () => {
  const setting = useSelector((state) => state.setting);

  if (setting.setting === null) {
    return <Loader screen='full' />;
  }

  const apiKey = setting.setting.firebase.apiKey;
  const authDomain = setting.setting.firebase.authDomain;
  const projectId = setting.setting.firebase.projectId;
  const storageBucket = setting.setting.firebase.storageBucket;
  const messagingSenderId = setting.setting.firebase.messagingSenderId;
  const appId = setting.setting.firebase.appId;
  const measurementId = setting.setting.firebase.measurementId;

  const firebaseConfig = {
    apiKey: apiKey,
    authDomain: authDomain,
    projectId: projectId,
    storageBucket: storageBucket,
    messagingSenderId: messagingSenderId,
    appId: appId,
    measurementId: measurementId,
  };

  console.log('Firebase Config:', firebaseConfig);

  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  } else {
    firebase.app();
  }

  const auth = firebase.auth();
  const messaging = firebase.messaging();

  // Request notification permission and handle token
  const requestPermission = async () => {
    try {
      //await messaging.requestPermission();
      const token = await messaging.getToken();
      console.log('FCM Token:', token);
      // Send the token to your server and update the UI if necessary
    } catch (err) {
      if (err.code === 'messaging/permission-blocked') {
        alert('You have blocked notifications. Please enable them in your browser settings.');
      } else {
        console.error('Unable to get permission to notify.', err);
      }
    }
  };

  // Call requestPermission to initialize notifications
  requestPermission();

  // Handle incoming messages
  onMessage(messaging, (payload) => {
    console.log('Message received. ', payload);
    // Customize notification here
    const notificationTitle = payload.notification.title;
    const notificationOptions = {
      body: payload.notification.body,
      icon: payload.notification.icon
    };

    new Notification(notificationTitle, notificationOptions);
  });

  return { auth, firebase, messaging };
};

export default FirebaseData;
